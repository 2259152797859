<template>
  <div class="video-container relative mb-10">
    <Youtube v-if="$props.source === 'youtube'" :width="null" :height="null" :src="playerOptions.src" ref="youtube" :autoplay="false" :vars="{controls: 0, autoplay: 0, showinfo: 0, iv_load_policy: 3, modestbranding: 1, rel: 0}"/>
    <template v-if="$props.source === 'vimeo'">
      <vue-vimeo-player :video-id="$props.videoId" :controls="false" :options="{autoplay: false}" ref="vimeo" />
      <button ref="playButton" class="play-button appearance-none absolute z-20 h-auto top-1/2 left-1/2" @click="playVimeo()">
        <svg class="bg-creme_light" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#FF6755" d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM176 168V344C176 352.7 180.7 360.7 188.3 364.9C195.8 369.2 205.1 369 212.5 364.5L356.5 276.5C363.6 272.1 368 264.4 368 256C368 247.6 363.6 239.9 356.5 235.5L212.5 147.5C205.1 142.1 195.8 142.8 188.3 147.1C180.7 151.3 176 159.3 176 168V168z"/></svg>
      </button>
      <button ref="pauseButton" class="pause-button absolute w-full h-full z-10 top-0 left-0" @click="pauseVimeo()"></button>
      <button ref="muteButton" class="flex items-center justify-center mute-button absolute z-20 top-2 left-2 bg-pumpkin_light p-2" @click="muteVimeo()">
        <i class="font-icons fas fa-volume-slash font-heavy text-creme_light"></i>
        <i class="font-icons fas fa-volume-high font-heavy text-creme_light"></i>
      </button>
    </template>
  </div>
</template>

<script>
import {defineAsyncComponent} from "vue";
import { vueVimeoPlayer } from 'vue-vimeo-player'

const Youtube = defineAsyncComponent(() => import("vue3-youtube"));

export default {
  name: "Video",
  props: ["source", "videoId"],
  components: {
    Youtube,
    vueVimeoPlayer
  },
  data() {
    return {
      playerOptions: {
        src: "https://www.youtube.com?v=" + this.$props.videoId
      },
      muted: false
    }
  },
  methods: {
    playVimeo() {
      this.$refs.vimeo.play();
      this.$refs.playButton.classList.add("hidden");
    },
    pauseVimeo() {
      this.$refs.vimeo.pause();
      this.$refs.playButton.classList.remove("hidden");
    },
    muteVimeo() {
      if(this.muted) {
        this.$refs.vimeo.unmute();
        this.muted = false;
        this.$refs.muteButton.classList.remove("muted");
      } else {
        this.$refs.vimeo.mute();
        this.muted = true;
        this.$refs.muteButton.classList.add("muted");
      }
    }
  }
}
</script>

<style lang="scss">

.video-container {

  & > div {

    position: relative;
    padding-bottom: 56.25%;

    iframe {

      position: absolute !important;
      top:0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .play-button {
    width: 50px;
    margin-left: -25px;
    margin-top: -25px;

    svg {
      border-radius: 50%;
    }
  }

  .mute-button {

    border-radius: 50%;
    width: 38px;
    height: 38px;

    .fa-volume-high {
      display: none;
    }

    &.muted {
      .fa-volume-slash {
        display: none;
      }
      .fa-volume-high {
        display: inline-block;
      }
    }
  }
}
</style>